<template>
  <div>
    <div class="credit-card-form mt-12 mb-6">
      <vs-row>
        <vs-col vs-type="flex" vs-lg="6" vs-sm="12" vs-justify="center">
          <credit-card
            :expireYear="expireYear.toString()"
            :expireMonth="expireMonth.toString()"
            :cardNumber="cardNumber"
            :name="name"
            :cvv="cvv"
            :showBack="showBack"
            :symbolImage="'/img/' + symbolImage + '.png'"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-lg="6" vs-sm="12">
          <vs-row>
            <div class="card-input w-full">
              <label for="cardName" class="card-input__label">
                {{ $t("Card_Owner") }}
              </label>
              <vs-input
                class="w-full"
                v-on:keypress="isLetter($event)"
                id="cardName"
                v-model="name"
                autocomplete="off"
                @input="errorAvaible = false"
              />
            </div>
            <div class="card-input w-full">
              <label for="cardNumber" class="card-input__label">
                {{ $t("Card_Number") }}
              </label>
              <input
                v-model="cardNumber"
                autofocus
                id="cardNumber"
                class="cart-number-input"
                autocomplete="off"
                v-mask="'#### #### #### ####'"
                @accept="onAcceptCardType"
                @input="errorAvaible = false"
              />
            </div>
            <div class="card-form__row w-full">
              <div class="card-form__group">
                <label for="cardMonth" class="card-input__label">
                  {{ $t("Expiration_Date") }}
                </label>
                <select
                  class="credit-cart-select mr-4"
                  id="cardMonth"
                  v-model="expireMonth"
                  @input="errorAvaible = false"
                >
                  <option value="" disabled selected>{{ $t("Month") }}</option>
                  <option
                    v-for="n in 12"
                    :value="n < 10 ? '0' + n : n"
                    :key="n"
                  >
                    {{ 10 > n ? "0" + n : n }}
                  </option>
                </select>
                <select
                  class="credit-cart-select mr-4"
                  id="cardYear"
                  v-model="expireYear"
                  @input="errorAvaible = false"
                >
                  <option value="" disabled selected>{{ $t("Year") }}</option>
                  <option
                    v-for="(n, $index) in 12"
                    :value="$index + currentYear"
                    :key="n"
                  >
                    {{ $index + currentYear }}
                  </option>
                </select>
              </div>

              <div class="">
                <div class="">
                  <label for="cardCvv" class="card-input__label">CVV</label>
                  <input
                    class="cart-number-input"
                    id="cardCvv"
                    v-model="cvv"
                    autocomplete="off"
                    v-mask="'###'"
                    @accept="onAcceptCvv"
                    @focus="showBack = true"
                    @blur="showBack = false"
                    @input="errorAvaible = false"
                  />
                </div>
              </div>
            </div>
            <vs-button
              :disabled="sendRequestEnable"
              class="card-form__button"
              @click="submitCard"
              >{{ $t("Submit") }}</vs-button
            >
            <vs-alert class="mt-2" color="danger" :active="errorAvaible">
              {{ errorMessage }}
            </vs-alert>
          </vs-row>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import CreditCard from "../components/CreditCard";

import Api from "@/api/api";
import API from "@/api/config";

export default {
  props: ["creditId"],
  data() {
    return {
      cardNumber: "",
      expireMonth: "",
      expireYear: "",
      name: "",
      cvv: "",
      currentYear: new Date().getFullYear(),
      showBack: false,
      symbolImage: "mastercard",
      sendRequestEnable: false,
      errorAvaible: false,
      errorMessage: "",
    };
  },
  components: {
    CreditCard,
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-zıİğĞüÜöÖçÇşŞ ]+$/.test(char)) return true;
      else e.preventDefault();
    },
    handleSubmitCard(status, data) {
      this.sendRequestEnable = false;
      if (status == 200) {
        this.$vs.notify({
          title: this.$t("Successful"),
          text: this.$t("Credit_Message_3"),
          color: "success",
        });
        this.$emit("paymentSuccess");
      } else if (status == 400) {
        this.errorAvaible = true;
        if (data.data.card_number != null) {
          this.errorMessage = data.data.card_number[0];
        } else if (data.data.detail != null) {
          this.errorMessage = data.data.detail;
        } else if (data.data.non_field_errors != null) {
          this.errorMessage = data.data.non_field_errors[0];
        }
      }
    },
    submitCard() {
      var controlInputs = false;
      if (this.name == "") {
        controlInputs = true;
      }
      if (this.cardNumber.length < 19) {
        controlInputs = true;
      }
      if (this.expireMonth == "") {
        controlInputs = true;
      }
      if (this.expireYear == "") {
        controlInputs = true;
      }
      if (this.cvv == "") {
        controlInputs = true;
      }
      if (controlInputs) {
        this.errorAvaible = true;
        this.errorMessage = this.$t("Credit_Message_4");
        return;
      }

      var params = {
        payment_method: 1,
        card_holder_name: this.name,
        card_number: this.cardNumber.replace(/ /g, ""),
        expire_month: this.expireMonth.toString(),
        expire_year: this.expireYear.toString(),
        cvc: this.cvv,
      };
      this.sendRequestEnable = true;

      Api.post(
        API.BASEURL + API.CREDIT_DENTAL + this.creditId + "/buy_credit-card/",
        this.handleSubmitCard,
        params
      );
    },
    onAcceptCardType(e) {
      const maskRef = e.detail;
      const type = maskRef.masked.currentMask.cardtype;

      if (type !== "Unknown") {
        this.symbolImage = type;
      }

      this.cardNumber = maskRef.value;
    },
    onAcceptCvv(e) {
      const maskRef = e.detail;
      this.cvv = maskRef.value;
    },
  },
};
</script>

<style scoped lang="scss">
.credit-cart-select {
  padding: 0.7rem !important;
  font-size: 1rem !important;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  flex: 1;
}
.cart-number-input {
  padding: 0.7rem !important;
  font-size: 1rem !important;
  color: inherit;
  position: relative;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}
.credit-card-form {
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}
.card-container {
  margin: 30px auto 50px auto;
  text-align: center;
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;

  &__inner {
    background: #fff;
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 20px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
  }

  &__col {
    flex: auto;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    &.-cvv {
      max-width: 150px;
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    border: none;
    font-weight: 700;
    margin-top: 10px;
    &:hover {
      background: darken(#38a294, 5%);
    }
  }
}

.card-input {
  margin-bottom: 5px;
  &__label {
    margin-bottom: 5px;
    color: #1a3b5d;
    width: 100%;
    display: block;
    text-align: left;
  }
  &__input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;

    &:hover,
    &:focus {
      border-color: #38a294;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
    &.-select {
      -webkit-appearance: none;
      background-image: url("/img/select.png");
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}
</style>
