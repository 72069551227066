<template>
  <div>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Confirm_Order')"
      @accept="goProfilePage"
      :active.sync="goProfileEnable"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span>{{ $t("Profile_Message_6") }} </span>
      </div>
    </vs-prompt>
    <!-- CREDIT PAGE -->
    <transition name="fade" mode="out-in">
      <div key="credit" v-if="!paymentPageActive">
        <!-- Credit Cards -->
        <div class="flex flex-wrap justify-center">
          <div
            class="
              creditCards
              xs:w-1/1
              sm:w-1/2
              md:w-1/3
              lg:w-1/4
              xl:w-1/6
              p-2
              justify-center
            "
            :key="credit.credit"
            v-for="(credit, index) in creditPatkets"
          >
            <div class="text-center packageDiv pb-6">
              <div
                :style="creditPackageTitleBar(index)"
                class="creditPackageTitle pt-2 pb-2"
              >
                <p>{{ credit.title }}</p>
              </div>
              <h2
                v-if="credit.discount"
                class="line-through mt-8"
                style="font-weight: 600"
              >
                {{ calculateOrginalPrice(credit) }}
              </h2>
              <div v-if="!credit.discount" class="mt-6"></div>
              <h2 class="mt-2" style="font-weight: 600">
                {{ creditPrice(credit) }}
              </h2>

              <vs-divider color="success" icon="star"></vs-divider>
              <p class="creditTitle">
                {{ credit.sub_title }} {{ $t("Credit") }}
              </p>

              <div v-if="!credit.is_company_info_filled">
                <vx-tooltip color="warning" :text="$t('Credit_Message_7')">
                  <vs-button
                    class="mt-6"
                    color="primary"
                    type="border"
                    @click="openPaymentPage(credit)"
                    >{{ $t("Buy") }}</vs-button
                  >
                </vx-tooltip>
              </div>
              <div v-else>
                <vs-button
                  class="mt-6"
                  color="primary"
                  type="border"
                  @click="openPaymentPage(credit)"
                  >{{ $t("Buy") }}</vs-button
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Credit History -->
        <div class="flex flex-wrap">
          <div class="vx-col p-2 w-full">
            <vx-card class="p-1">
              <vs-table :data="creditHistory">
                <template slot="header">
                  <h3 class="mt-2 mb-2">{{ $t("Credit_Purchase_History") }}</h3>
                </template>
                <template slot="thead">
                  <vs-th> {{ $t("Title") }} </vs-th>
                  <vs-th> {{ $t("Amount") }} </vs-th>
                  <vs-th> {{ $t("Bonus") }} </vs-th>
                  <vs-th> {{ $t("Status") }} </vs-th>
                  <vs-th> {{ $t("Type") }} </vs-th>
                  <vs-th> {{ $t("Date") }} </vs-th>
                  <vs-th> {{ $t("Price") }} </vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].title">
                      {{ data[indextr].title }}
                    </vs-td>

                    <vs-td :data="data[indextr].amount">
                      {{ data[indextr].amount }} </vs-td
                    ><vs-td :data="data[indextr].bonus">
                      {{ data[indextr].bonus }} </vs-td
                    ><vs-td :data="data[indextr].status">
                      <vs-chip
                        :color="getOrderStatusColor(data[indextr].status)"
                        >{{ $t(data[indextr].status) }}</vs-chip
                      > </vs-td
                    ><vs-td :data="data[indextr].payment_method">
                      {{ data[indextr].payment_method }} </vs-td
                    ><vs-td :data="data[indextr].created">
                      {{ getRelativeTime(data[indextr].created) }}
                    </vs-td>
                    <vs-td :data="data[indextr].price">
                      {{ data[indextr].price }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vx-card>
          </div>
          <div class="m-2 vx-col flex justify-center w-full">
            <vs-pagination
              :max="5"
              :total="totalPage"
              v-model="currentPage"
              @change="handleChangePage(currentPage)"
            />
          </div>
        </div>
      </div>

      <!-- PAYMENT PAGE -->
      <div key="payment" v-else>
        <vx-card class="creditPackage">
          <div class="vx-card p-4 mb-4">
            <div class="vx-row">
              <div class="vx-col w-full text-right">
                <vs-button @click="paymentPageActive = false" type="border">
                  {{ $t("Back") }}
                </vs-button>
              </div>
            </div>
          </div>

          <div style="text-align: -webkit-center">
            <div class="maxWidth pr-4 pl-4">
              <div class="creditCartInfo">
                <vs-row vs-type="flex">
                  <vs-col vs-type="flex" vs-lg="5" vs-xs="12">
                    <p class="title">{{ $t("Package_Title") }}</p>
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-lg="1"
                    vs-xs="0"
                    vs-justify="flex-end"
                  >
                    <p class="title mr-2">:</p>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="5" vs-xs="12">
                    <p class="values">{{ selectedCredit.title }}</p>
                  </vs-col>
                </vs-row>
                <vs-row>
                  <vs-col vs-type="flex" vs-lg="5" vs-xs="12">
                    <p class="title">{{ $t("Amount_Credit") }}</p>
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-lg="1"
                    vs-xs="0"
                    vs-justify="flex-end"
                  >
                    <p class="title mr-2">:</p>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="5">
                    <div class="values">
                      {{ selectedCredit.amount }}
                      <div class="values inline" v-if="selectedCredit.bonus">
                        + {{ selectedCredit.bonus }}
                      </div>
                    </div>
                  </vs-col>
                </vs-row>

                <vs-row>
                  <vs-col vs-type="flex" vs-lg="5" vs-xs="12">
                    <p class="title">{{ $t("Price") }}</p>
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-lg="1"
                    vs-xs="0"
                    vs-justify="flex-end"
                  >
                    <p class="title mr-2">:</p>
                  </vs-col>
                  <vs-col vs-type="flex" vs-w="5">
                    <p class="values">
                      {{ creditPrice(selectedCredit) }}
                    </p>
                  </vs-col>
                </vs-row>
              </div>
            </div>
            <div class="maxWidth pr-4 pl-4">
              <credit-card-form
                @paymentSuccess="paymentSuccessful"
                :creditId="this.selectedCredit.id"
              />
            </div>
          </div>
        </vx-card>
      </div>
    </transition>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";
import CreditCardForm from "./CreditCardFormDental";

import moment from "moment";

export default {
  data() {
    return {
      goProfileEnable: false,
      //Pagination
      currentPage: 1,
      totalPage: 1,

      paymentPageActive: false,

      credit: 0,

      selectedCredit: null,

      creditPatkets: [],
      creditHistory: [],
    };
  },
  computed: {
    getDentalCredit() {
      return this.$store.getters.getDentalCredit;
    },
  },
  methods: {
    calculateOrginalPrice(calPackage) {
      let withoutTax = calPackage.price / calPackage.tax;
      let withoutDiscount = (withoutTax * 100) / (100 - calPackage.discount);
      let price = (withoutDiscount * calPackage.tax).toFixed(0);

      if (calPackage.price_currency == "USD") {
        return "$" + price;
      } else if (calPackage.price_currency == "TRY") {
        return price + "₺";
      } else if (calPackage.price_currency == "EUR") {
        return price + "€";
      }
    },
    creditPackageTitleBar(index) {
      if (index == 0) {
        return {
          "background-color": "#cbcbcb",
        };
      } else if (index == 1) {
        return {
          "background-color": "#D0B047",
        };
      } else if (index == 2) {
        return {
          "background-color": "#96A0A2",
        };
      } else if (index == 3) {
        return {
          "background-color": "#34AA10",
        };
      } else if (index == 4) {
        return {
          "background-color": "#D2DAE2",
        };
      }
    },
    paymentSuccessful() {
      this.getCreditPurchaseHistory();
      this.paymentPageActive = false;
      this.$store.dispatch(
        "updateDentalCredit",
        this.getDentalCredit +
          this.selectedCredit.amount +
          this.selectedCredit.bonus
      );
    },
    creditPrice(credit) {
      if (credit.price_currency == "USD") {
        return "$" + credit.price.toFixed(0);
      } else if (credit.price_currency == "TRY") {
        return credit.price.toFixed(0) + "₺";
      } else if (credit.price_currency == "EUR") {
        return credit.price.toFixed(0) + "€";
      }
    },

    getRelativeTime(value) {
      return moment(String(value)).format("DD/MM/YYYY - hh:mm");
    },
    handleChangePage() {
      this.getCreditPurchaseHistory();
    },
    getOrderStatusColor(status) {
      if (status == "Approved") return "success";
      if (status == "Cancelled") return "danger";

      return "primary";
    },

    getCreditPurchaseHistory() {
      var params = {};
      if (this.currentPage != 1) {
        params.page = this.currentPage;
      }
      Api.get(
        API.BASEURL + API.CREDIT_DENTAL_HISTORY,
        this.handleGetCreditPurchaseHistory,
        params
      );
    },
    handleGetCreditPurchaseHistory(status, data) {
      if (status == 200) {
        this.totalPage = Math.ceil(data.count / 50);
        this.creditHistory = data.results;
      }
    },
    getCreditPacketsRequest() {
      Api.get(
        API.BASEURL + API.CREDIT_DENTAL,
        this.handleGetCreditPacketsRequest
      );
    },
    handleGetCreditPacketsRequest(status, data) {
      if (status == 200) {
        this.creditPatkets = data;
      }
    },
    goProfilePage() {
      setTimeout(() => {
        var params = {
          active_tab: 2,
        };
        this.$router
          .push({
            name: "user_profile",
            query: params,
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      }, 100);
    },
    openPaymentPage(creditPacket) {
      if (!creditPacket.is_company_info_filled) {
        this.goProfileEnable = true;
        return;
      }
      this.paymentPageActive = true;
      this.selectedCredit = creditPacket;
    },
    buyCredit() {
      var params = {
        payment_method: 0,
      };
      Api.post(
        API.BASEURL + API.CREDIT_DENTAL + this.selectedCredit.id + "/buy/",
        this.handleBuyCredit,
        params
      );
    },
    handleBuyCredit(status, data) {
      if (status == 400) {
        this.paymentPageActive = false;

        if (data.data.detail == "you have previous request !") {
          {
            this.havePreviousRequest = true;
            this.havePreviousRequestMessage = data.data.detail;
          }
        }
        this.$vs.notify({
          time: 4000,
          color: "warning",
          title: this.$t("Warning"),
          text: data.data.detail,
        });
      } else if (status == 200) {
        this.getCreditPurchaseHistory();
        this.paymentPageActive = false;

        this.$vs.notify({
          color: "success",
          title: this.$t("Successful"),
          text: this.$t("Credit_Message_2"),
        });
      }
    },
  },
  components: {
    CreditCardForm,
  },

  created() {
    this.credit = parseInt(localStorage.getItem("dentalCredit"));
    this.getCreditPacketsRequest();
  },
};
</script>

<style lang="scss">
th .vs-table-text {
  justify-content: flex-start;
}
.vs-pagination--mb {
  justify-content: center !important;
}
//input number'da arttır azalt butonlarını saklama
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<style lang="scss" scoped>
.maxWidth {
  max-width: 1000px;
}
.creditCartInfo {
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  height: fit-content;
  width: 100%;
  padding: 20px;
  margin: auto;
}
.packageDiv {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.creditPackageTitle {
  color: white;
  font-weight: 600;
  font-size: 1.2em;
  text-shadow: 0px 2px 2px #00000029;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.creditTitle {
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 2px 2px #00000029;
}
.creditPackage {
  height: min-content;
  .title {
    font-size: 14px;
    font-weight: 500;
  }
  .values {
    font-weight: 600;
  }
}
.cardIcons {
  width: 87px !important;
  height: 50px !important;
}
.bankTitle {
  font-weight: 600;
}
.bankIban {
  font-weight: 500;
}

.creditCards {
  width: 100%;
  min-width: 210px !important;
  max-width: 300px !important;
}
.image {
  width: 100%;
  height: 250px;
}
.sendButton {
  right: 0;
  position: relative;
}

.topBarFont {
  font-weight: 700;
  font-size: 14px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0.5s;
}
</style>
